<template>
    <span>
        <split-pane  :min-percent='10' :default-percent='12' split="vertical">     
            <!-- 人员资料，左侧组织架构 -->
            <template slot="paneL" v-if="!leftBool"> 
            <!-- <Sider v-if="!leftBool" hide-trigger class="siderCls" style="background:#fff,width: 240px;min-width: 240px; "> -->
                <leftTree  @child-nodeClick="nodeClick" :style="{height: scrollerHeight,overflow:'auto','padding-top':'3px'}"></leftTree>
            </template>
            <template slot="paneR">
                <div style="background:#fff; padding-left:15px;  "> 
                    <el-button type="text" size="small" @click="addRec" style="margin-right:15px;"><i class="el-icon-plus" style="margin-right:5px;font-size:13px;"></i>新增</el-button>
                    <el-button type="text" size="small"  @click="editRec" style="margin-right:10px;" ><i class="el-icon-edit" style="margin-right:5px;font-size:13px;"></i><span style="margin-right:10px;">编辑</span></el-button>
                    <el-button type="text" size="small"  @click="cancelRec" style="margin-right:10px;" ><i class="el-icon-delete" style="margin-right:5px;font-size:13px;"></i>作废记录</el-button>
                    
                    <el-button type="text" size="small"  @click="setPriv" style="margin-left:20px;"><i class="iconfont icon-xitongguanli" style="margin-right:5px;font-size:13px;"></i>设置人员权限</el-button>
                    <el-button type="text" size="small"  @click="fieldSet" style="margin-left:20px;"><i class="iconfont icon-ziyuan " style="margin-right:5px;font-size:13px;"></i>设置列字段显示隐藏</el-button>
                               
                    <el-select slot="prepend" v-model="selparam" placeholder="请选择列名" size="small"  @change="elchange" style="width:140px;height:30px;margin-left:15px">
                        <span v-for="item in tabCol" :key='item.key'>
                            <el-option v-if="item.thide==='N'" :label="item.title" :value="item.key"></el-option>
                        </span>
                    </el-select>
                    <el-input :placeholder="input_hold" v-model="txtparam" size="small" style="width:300px;height:30px; " @keyup.enter.native="qryTabData">   
                        <el-select v-model="oper" slot="prepend"   style="width:110px; " >
                            <span v-for="item in operArr" :key='item.IDSEQ'>
                                <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                            </span>
                        </el-select>                  
                    </el-input>        
                    <el-table  :data="tmpData.slice(0,rangeNumber)" 
                            border                            
                            size="small"
                            ref="refhr"
                            v-tablemore:rangeNumber=tableMore(rangeNumber)
                            style="overflow:auto;margin-top:5px;"
                            v-loading="loading"
                            :row-key="getRowKey"
                            element-loading-text="加载中"
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(255, 251, 255, 0.8)"
                            :height="tabHeight"
                            highlight-current-row
                            @row-click="rowClick" >
                        <el-table-column  v-for="(item ) in tabCol.filter(item=>item.thide=='N')" :key="item.key"
                            :prop="item.key"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                             
                            :sortable="item.key!=='RN'&&item.edcss!=='checkbox'"
                            :min-width="item.width">
                        </el-table-column>                    
                    </el-table>
                    <!-- <div style="line-height:30px;font-size:12px;font-weight:600;color:red">总记录行数：{{recNum}}</div> -->
                    <el-dialog style="position:fixed;" v-dialogDrag class="pub_dialog" :visible.sync="addModal" width="90%" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="6vh">
                        <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                            <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{type==='create'?'新增记录':'修改记录'}}</span>
                        </div> 
                        <div  >
                            <div style="display:flex;">
                                <div style=" padding-left:1px; "> 
                                    <el-timeline style="width:130px;margin-top:5px">
                                        <el-timeline-item
                                            v-for="(activity, index) in timeArr"
                                            :key="index"
                                            @click.native="handleTime(activity,index)"
                                            :icon="activity.ICON"
                                            :type="activity.TY"
                                            :color="currentIndex==index?'red':'#389'">
                                            {{activity.GPNM}}
                                        </el-timeline-item>
                                    </el-timeline>
                                </div>
                                <div style="height:88vh;overflow-y:auto;padding-bottom:100px;; " >               
                                    <span v-for="(gpitem,index) in tabFrmCol"  :key="index"  >
                                        <div :ref="gpitem.gpnm" style=" display:flex;flex-direction:column;border-left:2px solid #389; ">
                                            <div  class="one-toolbar">
                                                <span class="spanRow"> {{gpitem.gpnm}}  </span>
                                                <span v-if="gpitem.gpno=='56517'" style="color:white">
                                                    <el-button type="text" size="mini" @click="eduAdd()" ><i class="iconfont icon-xinzeng" style="padding-right:5px;margin-left:20px;color:white;font-size:10px">新增</i></el-button>
                                                    <el-button type="text" size="mini"  @click="eduEdit()"  ><i class="iconfont icon-bianji" style="padding-right:5px;color:white;font-size:10px">编辑</i></el-button>
                                                    <el-button type="text" size="mini"  @click="eduDel()"  ><i class="iconfont icon-shanchu1" style="padding-right:5px;color:white;font-size:10px">删除</i></el-button>
                                                </span> 
                                                <span v-if="gpitem.gpno=='57457'" style="color:white">
                                                    <el-button type="text" size="mini" @click="workAdd()" ><i class="iconfont icon-xinzeng" style="padding-right:5px;margin-left:20px;color:white;font-size:10px">新增</i></el-button>
                                                    <el-button type="text" size="mini"  @click="workEdit()"  ><i class="iconfont icon-bianji" style="padding-right:5px;color:white;font-size:10px">编辑</i></el-button>
                                                    <el-button type="text" size="mini"  @click="workDel()"  ><i class="iconfont icon-shanchu1" style="padding-right:5px;color:white;font-size:10px">删除</i></el-button>
                                                </span> 
                                                <span v-if="gpitem.gpno=='57458'" style="color:white">
                                                    <el-button type="text" size="mini" @click="homeAdd()" ><i class="iconfont icon-xinzeng" style="padding-right:5px;margin-left:20px;color:white;font-size:10px">新增</i></el-button>
                                                    <el-button type="text" size="mini"  @click="homeEdit()"  ><i class="iconfont icon-bianji" style="padding-right:5px;color:white;font-size:10px">编辑</i></el-button>
                                                    <el-button type="text" size="mini"  @click="homeDel()"  ><i class="iconfont icon-shanchu1" style="padding-right:5px;color:white;font-size:10px">删除</i></el-button>
                                                </span> 
                                            </div>
                                            <Form ref="addForm" :model="formCol" :label-width="100"  :rules="ruleValidate"  label-position="right" inline >                           
                                                <span v-for="(item,index) in gpitem.gplist" :key="index" >
                                                    <FormItem  class="item-width" :label=" item.FLDNM" :prop="item.FLDNO" v-if="item.FHIDE==='N'">  
                                                        <el-checkbox  v-if="item.EDCSS==='checkbox'" true-label="Y"  false-label="N" v-model="formCol[item.FLDNO]"  ></el-checkbox>                                    
                                                        <Select v-else-if="item.EDCSS==='select' && item.FLDNO==='STFG'" v-model="formCol[item.FLDNO]"   clearable>                            
                                                            <Option v-for="(item,index) in stfgArr" :value="item.CODE" :key='index'>{{item.CNAME}}</Option>   
                                                        </Select>
                                                        <Select v-else-if="item.EDCSS==='select' && item.FLDNO==='QUITCAT'" v-model="formCol[item.FLDNO]"   clearable>                            
                                                            <Option v-for="(item,index) in quitArr" :value="item.CODE" :key='index'>{{item.CNAME}}</Option>   
                                                        </Select>
                                                        <Select filterable v-else-if="item.EDCSS==='select' && item.FLDNO==='JOBNO'" v-model="formCol[item.FLDNO]"   clearable>                            
                                                            <Option v-for="(item,index) in jobArr" :value="item.CODE" :key='index'>{{item.CNAME}}</Option>   
                                                        </Select>
                                                        <Select filterable v-else-if="item.EDCSS==='select' && item.FLDNO==='CLNTNO'" v-model="formCol[item.FLDNO]"  clearable>                            
                                                            <Option v-for="item  in cltArr" :value="item.IDNO" :key='item.IDNO'>{{item.CLNTNM}}</Option>   
                                                        </Select>
                                                        <el-select size="small" v-loadmore:rangeNumber="loadMore(rangeNumber)" filterable v-else-if="item.EDCSS==='select' && item.FLDNO==='SUPNO'" v-model="formCol[item.FLDNO]" style="width:100%" clearable>                            
                                                            <el-option v-for="(item,index)  in supArr.slice(0,rangeNumber)"  :key="index" :value="item.IDNO" :label="item.SUPNM" > </el-option>   
                                                        </el-select>
                                                        <Select v-else-if="item.EDCSS==='select' && item.FLDNO==='SEX'" v-model="formCol[item.FLDNO]"    >                            
                                                            <Option v-for="(item,index) in sexArr" :value="item.CODE" :key='index'>{{item.CNAME}}</Option>   
                                                        </Select>
                                                        <Select v-else-if="item.EDCSS==='select' && item.FLDNO==='VOLK'" v-model="formCol[item.FLDNO]"   filterable >                            
                                                            <Option v-for="(item,index) in volkArr" :value="item.CNAME" :key='index'>{{item.CNAME}}</Option>   
                                                        </Select>
                                                        <!-- 尊称  -->
                                                        <Select v-else-if="item.EDCSS==='select' && item.FLDNO==='WHAT'" v-model="formCol[item.FLDNO]"   filterable >                            
                                                            <Option v-for="(item,index) in whatArr" :value="item.CNAME" :key='index'>{{item.CNAME}}</Option>   
                                                        </Select>
                                                        <!-- 用户类别 multiple多选  -->                                                 
                                                        <Select multiple  v-else-if="item.EDCSS==='select' && item.FLDNO==='EMPTYPE'" v-model="formCol[item.FLDNO]" clearable>                            
                                                            <OptionGroup :label="item.gpnm" v-for="(item,index) in typeArr" :key="index">
                                                                    <Option v-for="item in item.gplist" :value="item.CODE" :key="item.CODE">{{ item.CNAME}}</Option>
                                                            </OptionGroup>  
                                                        </Select>
                                                        <!-- 会员等级  单选-->
                                                        <Select   v-else-if="item.EDCSS==='select' && item.FLDNO==='EMPLVL'" v-model="formCol[item.FLDNO]" clearable>                            
                                                            <OptionGroup :label="item.gpnm" v-for="(item,index) in emplvlArr" :key="index">
                                                                    <Option v-for="item in item.gplist" :value="item.CODE" :key="item.CODE">{{ item.CNAME}}</Option>
                                                            </OptionGroup>  
                                                        </Select>
                                                        <!-- 政治面貌  -->
                                                        <Select  v-else-if="item.EDCSS==='select' && item.FLDNO==='POLITIC'" v-model="formCol[item.FLDNO]" clearable>                            
                                                            <OptionGroup :label="item.gpnm" v-for="(item,index) in politicArr" :key="index">
                                                                    <Option v-for="item in item.gplist" :value="item.CODE" :key="item.CODE">{{ item.CNAME}}</Option>
                                                            </OptionGroup>  
                                                        </Select>
                                                        <!-- 婚否 （最新修改） -->
                                                        <Select  v-else-if="item.EDCSS==='select' && item.FLDNO==='ISMARRY'" v-model="formCol[item.FLDNO]" clearable>                            
                                                            <!-- <OptionGroup :label="item.gpnm" v-for="(item,index) in ismarryArr" :key="index"> -->
                                                                    <Option v-for="item in ismarryArr" :value="item.CODE" :key="item.CODE">{{ item.CNAME}}</Option>
                                                            <!-- </OptionGroup>   -->
                                                        </Select>
                                                        <!-- 健康状况  -->
                                                        <Select  v-else-if="item.EDCSS==='select' && item.FLDNO==='HEALTH'" v-model="formCol[item.FLDNO]" clearable>                            
                                                            <OptionGroup :label="item.gpnm" v-for="(item,index) in healthArr" :key="index">
                                                                    <Option v-for="item in item.gplist" :value="item.CODE" :key="item.CODE">{{ item.CNAME}}</Option>
                                                            </OptionGroup>  
                                                        </Select>
                                                        <!-- 技术职称  multiple多选-->
                                                        <Select  multiple v-else-if="item.EDCSS==='select' && item.FLDNO==='JOBTITLE'" v-model="formCol[item.FLDNO]" clearable>                            
                                                            <OptionGroup :label="item.gpnm" v-for="(item,index) in jobtitleArr" :key="index">
                                                                    <Option v-for="item in item.gplist" :value="item.CODE" :key="item.CODE">{{ item.CNAME}}</Option>
                                                            </OptionGroup>  
                                                        </Select>
                                                        <!-- 外语能力  multiple多选-->
                                                        <Select multiple  v-else-if="item.EDCSS==='select' && item.FLDNO==='ENGLVL'" v-model="formCol[item.FLDNO]" clearable>                            
                                                            <OptionGroup :label="item.gpnm" v-for="(item,index) in englvlArr" :key="index">
                                                                    <Option v-for="item in item.gplist" :value="item.CNAME" :key="item.CODE">{{ item.CNAME}}</Option>
                                                            </OptionGroup>  
                                                        </Select>
                                                        <!-- 语言熟练程度  单选-->
                                                        <Select   v-else-if="item.EDCSS==='select' && item.FLDNO==='LANG'" v-model="formCol[item.FLDNO]" clearable>                            
                                                            <OptionGroup :label="item.gpnm" v-for="(item,index) in langArr" :key="index">
                                                                    <Option v-for="item in item.gplist" :value="item.CODE" :key="item.CODE">{{ item.CNAME}}</Option>
                                                            </OptionGroup>  
                                                        </Select>
    
                                                        <Cascader v-else-if="item.EDCSS==='cascade' && item.FLDNO==='NATION'"
                                                            v-model="formCol[item.FLDNO]"
                                                            clearable
                                                            filterable   
                                                            :data="countryarea"
                                                            @on-change="handleChange">
                                                        </Cascader>
                                                        <!-- 入职日期 -->
                                                        <el-date-picker type="date" size="small"  v-else-if="item.EDCSS==='date'" v-model="formCol[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%"></el-date-picker> 

                                                        <Input type="number"   v-else-if="item.EDCSS==='number'" v-model="formCol[item.FLDNO]"  clearable/> 
                                                        <el-input type="text" readonly v-else-if="item.FLDNO==='PRDCAT'" v-model="formCol[item.FLDNO]" class="elinput" clearable>
                                                        <el-button size="mini" slot="append" icon="el-icon-search" @click="searchPrd"></el-button>
                                                        </el-input> 
                                                        <el-input type="text" readonly v-else-if="item.FLDNO==='DEPTNO'" v-model="formCol[item.FLDNO]" class="elinput" clearable>
                                                            <el-button size="mini" slot="append" icon="el-icon-search" @click="srchDept" style="width:10px;"></el-button>
                                                        </el-input>  

                                                        <Input v-else type="text" :disabled="item.DISABLED==='Y'?true:false " v-model="formCol[item.FLDNO]"  clearable/>
                                                    </FormItem>
                                                </span>                           
                                            </Form>
                                        </div>
                                        <!-- 发票抬头 -->
                                        <div v-if="gpitem.gpno=='56518'" style="border-left:2px solid #389;">
                                            <el-table  :data="invData" 
                                                    border                            
                                                    disabled-hover
                                                    style="overflow:auto; "
                                                    size="small"
                                                    ref="invtab"
                                                    height="160" 
                                                    highlight-current-row>                        
                                                <el-table-column  v-for="(item,index ) in invCol" :key="index" 
                                                    :prop="item.key"
                                                    :label="item.title"
                                                    show-overflow-tooltip
                                                    :align="item.align"                                  
                                                    sortable
                                                    :width="item.width">
                                                </el-table-column>  
                                                <template slot="empty">暂无数据</template>                                         
                                            </el-table> 
                                        </div>
                                        <!-- 收货地址 -->
                                        <div v-if="gpitem.gpno=='20209'" style="border-left:2px solid #389;">
                                            <el-table  :data="rcvData" 
                                                border                            
                                                disabled-hover
                                                style="overflow:auto; "
                                                size="small"
                                                ref="shptab"
                                                height="160" 
                                                highlight-current-row>
                                                <template v-for="(item ,index) in shpCol">
                                                <el-table-column   v-if="item.THIDE==='N'" :key="index"
                                                    :prop="item.key"
                                                    :label="item.title"
                                                    show-overflow-tooltip
                                                    :align="item.align"                                  
                                                    sortable
                                                    :width="item.width">
                                                </el-table-column>  
                                                </template>  
                                                <template slot="empty">暂无数据</template>                 
                                            </el-table> 
                                        </div>
                                        <!-- 教育经历 -->
                                        <div v-if="gpitem.gpno=='56517'" style="border-left:2px solid #389;">
                                            <el-table  :data="eduData" 
                                                    border                            
                                                    disabled-hover
                                                    style="overflow:auto; "
                                                    size="small"
                                                    ref="eduref"
                                                    height="160" 
                                                    @row-click="eduClick" 
                                                    highlight-current-row>                        
                                                <el-table-column  v-for="(item,index ) in eduCol" :key="index" 
                                                    :prop="item.key"
                                                    :label="item.title"
                                                    show-overflow-tooltip
                                                    :align="item.align"                                  
                                                    sortable
                                                    :width="item.width">
                                                </el-table-column>  
                                                <template slot="empty">暂无数据</template>                                         
                                            </el-table> 
                                        </div>
                                        <!-- 工作经历 -->
                                        <div v-if="gpitem.gpno=='57457'" style="border-left:2px solid #389;">
                                            <el-table  :data="workData" 
                                                    border                            
                                                    disabled-hover
                                                    style="overflow:auto; "
                                                    size="small"
                                                    ref="workref"
                                                    height="160" 
                                                    @row-click="workClick" 
                                                    highlight-current-row>                        
                                                <el-table-column  v-for="(item,index ) in workCol" :key="index" 
                                                    :prop="item.key"
                                                    :label="item.title"
                                                    show-overflow-tooltip
                                                    :align="item.align"                                  
                                                    sortable
                                                    :width="item.width">
                                                </el-table-column>  
                                                <template slot="empty">暂无数据</template>                                         
                                            </el-table>
                                        </div>
                                        <!-- 家庭成员-->
                                        <div v-if="gpitem.gpno=='57458'" style="border-left:2px solid #389;">
                                            <el-table  :data="homeData" 
                                                    border                            
                                                    disabled-hover
                                                    style="overflow:auto; "
                                                    size="small"
                                                    ref="homeref"
                                                    height="160" 
                                                    @row-click="homeClick" 
                                                    highlight-current-row>                        
                                                <el-table-column  v-for="(item,index ) in homeCol" :key="index" 
                                                    :prop="item.key"
                                                    :label="item.title"
                                                    show-overflow-tooltip
                                                    :align="item.align"                                  
                                                    sortable
                                                    :width="item.width">
                                                </el-table-column>  
                                                <template slot="empty">暂无数据</template>                                         
                                            </el-table>
                                        </div>
                                        <!-- 我的附件-->
                                        <div v-if="gpitem.gpno=='57459'" style="border-left:2px solid #389;">
                                            <el-row style="width:100%">
                                                <el-col :span="7" v-for="(item,index) in idArr" :key="index" :label="item.CNAME" :value="item.CNAME">
                                                    <div style="line-height:30px;background:#389;color:#fff;padding-left:5px;margin-right:5px"><i class="iconfont icon-xuanze" style="margin-right:5px"></i>{{item.CNAME}}</div>
                                                    <div style="float:left">
                                                        <div v-for="(item1,index) in attData" :key="index" :label="item1.title"  style="float:left">
                                                            <el-row>
                                                                <el-image v-if="item1.FILETY==item.CNAME" :src="item1.FILEPATH" style="width:70px;height:70px;margin-left:20px;" ></el-image>
                                                            </el-row>
                                                            <el-row >
                                                                <el-link v-if="item1.FILETY==item.CNAME" :underline="false" style="margin-left:28px;color:red;background-color:#ccc" @click="attDel(item1.IDSEQ,item1.LSTSEQ)"><Icon type="md-close" /> 删除</el-link>
                                                            </el-row>                                                
                                                        </div> 
                                                        <div  style="float:left">
                                                            <el-row>
                                                                <el-image src="/assets/basedict/nopic.png" style="width:70px;height:70px;margin-left:20px;" @click="selPicture(item.CNAME)"></el-image>
                                                            </el-row>
                                                            <el-row >
                                                                <el-link  :underline="false" style="margin-left:28px;color:blue;" @click="selPicture(item.CNAME)"><i class="iconfont icon-xinzeng"></i> 添加</el-link>
                                                            </el-row>    
                                                        </div>                                         
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div style="height:40px;line-height:35px;border-top:0px solid #ccc;margin-top:4px; width:100% " >
                            <span style="float:right;" >
                                <el-button type="info" size="small"  @click="addModal=false"  icon="iconfont icon-shanchu" style=" margin-right:50px; ">取消</el-button>
                                <el-button type="primary" size="small"  @click="modal_ok"  icon="iconfont icon-baocun"  style=" margin-right:100px; " >保存</el-button>
                            </span>
                        </div>
                    </el-dialog> 
                    <el-dialog  :visible.sync="prdModal" width="1110px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
                        <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                            <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">产品选择</span>
                        </div>                    
                        <registerPrd @child-exit="exitModal" @child-ok="okModal"></registerPrd>
                    </el-dialog>
                    <el-dialog  :visible.sync="treeModal" width="610px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
                        <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                            <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">{{eduFlag==='create'?'新增记录':'编辑记录'}}</span>
                        </div>                    
                        <div style="height:86vh;overflow-y:auto">
                            <treeSelect @child-exit="btnExit" @child-ok="btnOk" :param_idseq="paramTree"></treeSelect>
                        </div>
                    </el-dialog>
                </div>
            </template>   
        </split-pane>
        <!-- 设置权限-->
        <el-dialog  :visible.sync="privDialog" width="80%" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="0vh">
            <div style="height:90vh;overflow-y:auto">
                <userPriv :EMPNO="parempno" ref="refpriv"></userPriv>
            </div>
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">设置用户权限</span>
            </div>
        </el-dialog>
        <!-- 字段框 -->
        <el-dialog  :visible.sync="fieldWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="0vh">
            <div style=" margin-top:10px;display:flex;background:#dcdfe6 ">
                <div style="width:290px; line-height:35px">字段名称</div>
                <div style="width:70px; line-height:35px">隐藏</div>
            </div>
            <div style="height:80vh;overflow-y:auto;">
                <div style="line-height:35px;border-bottom:1px dashed #dcdfe6;" v-for="(item ) in fieldArr" :key="item.FLDNO">
                    <div style="display:flex" v-if="item.THIDE==='N'" >
                        <div style="width:290px" >{{item.FLDNM}}</div>
                        <div style="width:70px;margin-left:20px;" >
                            <el-checkbox :disabled="item.FLDNO==='EMPNO'||item.FLDNO==='RN'" true-label="Y"  false-label='' v-model="fldSel[item.FLDNO]" @change="fldChange(item.FLDNO,fldSel[item.FLDNO])" ></el-checkbox> 
                        </div>
                    </div>
                </div>
            </div>
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">字段隐藏设置</span>
            </div>
            <div slot="footer">
                <Button type="primary"   @click="fieldWin=false" style="margin-right:40px;">取消</Button>
                <Button type="primary"   @click="fldOk" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
                <!-- 我的附件弹窗 -->
        <el-dialog  :visible.sync="picDialog" width="1060px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="2vh">
            <docSelect @doc-exit="picExit" @doc-sure="picSure" ref="refdoc"></docSelect>
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">{{addtype}}</span>
            </div>
        </el-dialog>
        
        <!-- 提示框 -->
        <el-dialog  :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">提示</span>
            </div>
            <div style=" margin:15px 10px">
                {{prompt}}
            </div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 确认框 -->
        <el-dialog  :visible.sync="confirmWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div style=" margin:15px 10px">
                {{cfmprompt}}
            </div>
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">提示</span>
            </div>
            <div slot="footer">
                <Button type="primary"   @click="confirmWin=false" style="margin-right:40px;">取消</Button>
                <Button type="primary"   @click="cfmOk(cfmVal)" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>  
        <el-dialog  :visible.sync="eduModel" width="730px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">{{eduFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div>                 
            <Form :model="eduRow" :label-width="110" ref="eduForm"  label-position="right" inline  >  
            <span v-for="(item,index) in eduCol" :key="index" >
                <FormItem  :label="item.FLDNM"  class="item-width" v-if="item.FHIDE==='N'" :prop="item.FLDNO"  >                  
                    <el-date-picker type="date"  v-if="item.EDCSS==='date'" v-model="eduRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                    <el-input-number   v-else-if="item.EDCSS==='number'" v-model="eduRow[item.FLDNO]" style="width:100%" ></el-input-number>   
                    <el-select   v-else-if="item.FLDNO==='SCHLVL'" v-model="eduRow[item.FLDNO]" style="width:100%" >
                        <el-option v-for="item in studyArr" :key="item.CNAME" :value="item.CNAME" :label="item.CNAME" >
                        </el-option>
                    </el-select>   
                    <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false "  v-model="eduRow[item.FLDNO]"></el-input>
                </FormItem>
            </span>
            </Form>
            <div slot="footer" style="height:30px;">
                <Button type="text"    @click="eduModel=false" style="margin-right:25px;">退出</Button>  
                <Button type="primary" plain   @click="eduOk" style="margin-right:25px;">保存</Button>          
            </div>
        </el-dialog>
        <el-dialog  :visible.sync="workModel" width="730px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">{{workFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div>                                                     
            <Form :model="workRow" :label-width="110" ref="workForm"  label-position="right" inline  >  
            <span v-for="(item,index) in workCol" :key="index" >
                <FormItem  :label="item.FLDNM"  class="item-width" v-if="item.FHIDE==='N'" :prop="item.FLDNO"  >                  
                    <el-date-picker type="date"  v-if="item.EDCSS==='date'" v-model="workRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                    <el-input-number   v-else-if="item.EDCSS==='number'" v-model="workRow[item.FLDNO]" style="width:100%" ></el-input-number>   
                    <el-select   v-else-if="item.FLDNO==='QUITRSN'" v-model="workRow[item.FLDNO]" style="width:100%" >
                        <el-option v-for="item in quitArr" :key="item.CNAME" :value="item.CNAME" :label="item.CNAME" >
                        </el-option>
                    </el-select>   
                    <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false "  v-model="workRow[item.FLDNO]"></el-input>
                </FormItem>
            </span>
            </Form>
            <div slot="footer" style="height:30px;">
                <Button type="text"    @click="workModel=false" style="margin-right:25px;">退出</Button>  
                <Button type="primary" plain   @click="workOk" style="margin-right:25px;">保存</Button>          
            </div>
        </el-dialog> 
        <el-dialog  :visible.sync="homeModel" width="730px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">{{homeFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div>                                                     
            <Form :model="homeRow" :label-width="110" ref="homeForm"  label-position="right" inline  >  
            <span v-for="(item,index) in homeCol" :key="index" >
                <FormItem  :label="item.FLDNM"  class="item-width" v-if="item.FHIDE==='N'" :prop="item.FLDNO"  >                  
                    <el-date-picker type="date"  v-if="item.EDCSS==='date'" v-model="homeRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                    <el-input-number   v-else-if="item.EDCSS==='number'" v-model="homeRow[item.FLDNO]" style="width:100%" ></el-input-number>   
                    <el-select   v-else-if="item.FLDNO==='WHAT'" v-model="homeRow[item.FLDNO]" style="width:100%" >
                        <el-option v-for="item in relateArr" :key="item.CNAME" :value="item.CNAME" :label="item.CNAME" >
                        </el-option>
                    </el-select>   
                    <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false "  v-model="homeRow[item.FLDNO]"></el-input>
                </FormItem>
            </span>
            </Form>
            <div slot="footer" style="height:30px;">
                <Button type="text"    @click="homeModel=false" style="margin-right:25px;">退出</Button>  
                <Button type="primary" plain   @click="homeOk" style="margin-right:25px;">保存</Button>          
            </div>
        </el-dialog>  
    </span>
</template>
<script>
import { getTabColOrData,getBaseData } from '../../api/user' 
import {stfgArray,quitArray,countryArray,jobArray,studyArray,relateArray,idArray,volkArray,whatArray,paramData} from '../../api/Select'
import {filterArrObj } from '../../api/Tools'
import registerPrd from '@/components/forms/productType.vue'
import treeSelect from '@/components/forms/treeSelect.vue'
import leftTree from '@/components/forms/leftTree.vue'
import docSelect from '../docmgt/back_doc.vue'
import userPriv from '../sysmgt/Sys_userpriv.vue'
export default {
    name: 'hr_employee',
    props:['leftBool','empBool','empNo'],
    data () {
        return {
            parempno:'',
            cfmVal:'', //确认框传来变量
            confirmWin:false,
            fldArr:[],
            fieldWin:false,
            selectAttType:'',//选中的图片分类
            picList:[],//图片列表
            picDialog:false,//附件modal
            addModal:false, //modal 
            prdModal:false, //modal 
            treeModal:false, //modal 
            privDialog:false,
            eduModel:false,
            workModel:false,
            homeModel:false,
            imgModel:false,
            promptWin:false,
            fldSel:{"RN":"","IDSEQ":"","EMPNO":"","CORPNM":"","LASTNM":"","EMPNM":"","WHAT":"","MIDDLE":"","ENAME":"","EMPTYPE":"","EMPTYPENM":"","EMPLVL":"","EMPLVLNM":"","DEPTNO":"","JOBNO":"","JOBNM":"","NATION":"","NATIONM":"","CTRYCODE":"","TELNO":"","MAILADDR":"","ISMAIL":"","EMPDIS":"","BIRTHDTTM":"","AGE":"","IDNO":"","SEX":"","SEXNM":"Y","LINKED":"","WXAPPID":"","HOBBY":"","SPECIAL":"","ISMARRY":"","ISMARRY1":"","AREACODE":"","PHONENO":"","EXTNO":"","HEALTH":"","HEALTH1":"","JOBTITLE":"","JOBTITLE1":"","ENGLVL":"","LANG":"","LANG1":"","POLITIC":"","POLITIC1":"","ADDR":"","PASTNM":"","VOLK":"","RMK":"","QQNO":"","SKYPE":"","FACDTTM":"","REGID":"","WXOPENID":"","BANKNO":"","BANKNM":"","SUBBANK":"","SWIFT":"","BANKADDR":"","STFG":"","STFGNM":"","QUITCAT":"","QUITCATNM1":"","QUITRSN":"","STREASON":"","UPDUSER":"","UPDDTTM":"","ADDUSER":"","ADDDTTM":""},
            cfmprompt:'',
            prompt:'',
            rangeNumber:40,
            eduRow:{},
            uploadData:{}, //上传数据
            paramTree:'',
            popShow:true,
            input_hold:'请输入内容... 按回车键以查询',
            selparam:'',// select 绑定值
            txtparam:'', // input 绑定值
            oper:'like',
            operArr:[],
            tabCol:[] ,//表列集合
            tmpData:[] ,// 
            tabFrmCol:[] ,//表单列集合
            baseData:[] ,//表数据
            v_url:this.$store.state.queryUrl , //api请求路径     
            v_likeurl:this.$store.state.baseUrl+'sysprivs/getLikeManyRec', //多记录查询 like模式    
            v_formurl:this.$store.state.baseUrl+'user/getFormGroup', //多记录查询 like模式       
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            v_idseq:this.$cookies.get('idseq') ,//api 请求密码
            loading:false,// table加载bool
            rowIdseq:'-1',
            type:'create',
            ruleValidate: {},
            idType:'',
            imgsrc:'',
            stfgBool:true,
            formCol:{}, //表格单行记录 model
            stfgArr:[] , //状态集合
            quitArr:[] , //离职集合
            typeArr:[] , //用户类别
            emplvlArr:[] , //会员等级
            politicArr:[] , //政治面貌
            ismarryArr:[] , //婚否
            healthArr:[] , //健康状况
            jobtitleArr:[] , //技术职称
            englvlArr:[] , //外语能力
            langArr:[] , //语言熟练程度
            countryarea:[], //级联数据源
            areaCol:[] ,//国家区号
            jobArr:[] , //职位
            studyArr:[] , //学历
            rowIndex:'-1',
            facdttm:'',   //入职日期
            birthdttm:'', //出生日期
            cfmidseq:'',
            cfmlstseq:'',
            sexArr:[{'CODE':'19902','CNAME':'男' },{ 'CODE':'19903','CNAME':'女'}] ,
            volkArr:[],//民族
            whatArr:[],//尊称
            invCol:[] ,//发票列集合
            invData:[] ,// 发票数据
            shpCol:[] ,//收货列集合
            rcvData:[] ,// 收货地址数据
            eduCol:[] ,//教育列集合
            eduData:[] ,//教育数据
            eduFlag:'',
            workCol:[] ,//工作列集合
            workData:[] ,//工作数据
            workRow:{},
            workFlag:'create',
            homeCol:[] ,//工作列集合
            homeData:[] ,//工作数据
            homeRow:{},
            homeFlag:'create',
            relateArr:[],
            idArr:[],
            attCol:[] ,//附件列集合
            attData:[] ,//附件数据
            uploadUrl:this.$store.state.baseUrl +'auth/login/uploadImg',
            Accept: "png,jpg,jpeg", //上传文件格式限制
            attIndex:'-1',
            v_many:this.$store.state.manyUrl , //api请求路径 
            fieldArr:[], // 
            fldCond:'', //选 中字段隐藏字符串
            imgArr:[],  //图片类型,
            addtype:'',
            supArr:[],
            cltArr:[],
            recNum:0,
            timeArr:[],
            v_lvl:'0',
            currentIndex:'-1',
        }
    },
    created () {  
        this.initField()      
        this.getTabCol() //得到 表列数组 
        if (this.empBool){
            this.getFormData('empno',this.empNo)
        }else{
            this.getFormData('','','','','','',' and rownum<100') //初始查询表数据   
        }
        this.getFormGpData() //表单 分组 数据  
        
        //国家代号
        //getBaseData(this.$store.state.nologinUrl, '','','','','v_sys_base'," and parentno in (select code from sys_base where parentno='238')").then(res=>{
        getBaseData(this.$store.state.nologinUrl, '','','','','v_sys_base'," start with code='238' connect by prior code=parentno").then(res=>{
            this.areaCol=res.data.result
        })
        //收货地址表字段
        getBaseData(this.$store.state.nologinUrl, 'idseq','24314','','','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
            this.shpCol=res.data.result
        })
        //发票表字段
        getBaseData(this.$store.state.nologinUrl, 'idseq','24313','','','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
            this.invCol=res.data.result
        })
        //教育背景字段
        getBaseData(this.$store.state.nologinUrl, 'idseq','24332','thide','N','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
            this.eduCol=res.data.result
        })
        //工作经历字段
        getBaseData(this.$store.state.nologinUrl, 'idseq','24333','thide','N','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
            this.workCol=res.data.result
        })
        //家庭成员字段
        getBaseData(this.$store.state.nologinUrl, 'idseq','24334','thide','N','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
            this.homeCol=res.data.result
        })
        //附件字段
        getBaseData(this.$store.state.nologinUrl, 'idseq','24336','thide','N','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
            this.attCol=res.data.result
        })
    },
    components: {
        registerPrd,
        treeSelect,
        leftTree,
        docSelect,
        userPriv
    },
    mounted () {       
        //操作符
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        })
        paramData('','','v_gpline').then((res)=>{
            this.timeArr =res.data.result
        })  
        //动态生成表单验证规则
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','107','fty','HEAD','NOTNULL','Y').then((res) => {
            let ruleObj={}
            let ruleVal=[]
            this.notNull = res.data
            res.data.forEach(item => {
                let v_fldnm =item.FLDNM
                let obj1={required:true,message:v_fldnm+'不能为空'}
                ruleVal =[]
                ruleVal.push(obj1)
                ruleObj[item.FLDNO]=ruleVal                  
            });
            this.ruleValidate =ruleObj
        });
        //员工状态
        stfgArray().then((res)=>{
            this.stfgArr =res.data.result
        }) 
        //离职类型
        quitArray().then((res)=>{
            this.quitArr =res.data.result
        });
        //职位名称
        jobArray().then((res)=>{
            this.jobArr =res.data.result
        });
        //国家
        countryArray().then((res)=>{
            this.countryarea =res.data
        });
        //客户
        paramData( 'stfg','Y','V_bs_clnt' ).then(res=>{
            this.cltArr=res.data.result
        }) 
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'V_BASE_GPNM','parentno','38651','gpnm','','','','').then((res) => {
            this.typeArr = res.data.result               
        });
        //会员等级  
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'V_BASE_GPNM','parentno','20136','gpnm','','','','').then((res) => {
            this.emplvlArr = res.data.result               
        });
        //政治面貌  
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'V_BASE_GPNM','parentno','19725','gpnm','','','','').then((res) => {
            this.politicArr = res.data.result               
        });
        paramData('PARENTNO','19892','V_SYS_BASE').then((res)=>{
            this.ismarryArr =res.data.result
        }); 
        //供应商
        paramData('','' ,'V_COMBO_SUP').then((res)=>{               
            this.supArr =res.data.result
        })
        //健康状况  
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'V_BASE_GPNM','parentno','19893','gpnm','','','','').then((res) => {
            this.healthArr = res.data.result               
        });
        //学历名称
        studyArray().then((res)=>{
            this.studyArr =res.data.result
        });
        //与我的关系
        relateArray().then((res)=>{
            this.relateArr =res.data.result
        });
        //证件类型
        idArray().then((res)=>{
            this.idArr =res.data.result
        });
        //籍贯类型
        volkArray().then((res)=>{
            this.volkArr =res.data.result
        });
        //尊称
        whatArray().then((res)=>{
            this.whatArr =res.data.result
        });
        //技术职称  
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'V_BASE_GPNM','parentno','19724','gpnm','','','','').then((res) => {
            this.jobtitleArr = res.data.result               
        });
        //外语能力  
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'V_BASE_GPNM','parentno','19895','gpnm','','','','').then((res) => {
            this.englvlArr = res.data.result               
        });
        //语言熟练程度  
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'V_BASE_GPNM','parentno','19911','gpnm','','','','').then((res) => {
            this.langArr = res.data.result               
        });
        getBaseData(this.$store.state.nologinCasUrl, 'parentno','0','','','V_PRD_CATEGORY','').then(res=>{
            this.prdType=res.data
        });
        
    } ,
    computed: {
        tabHeight() {
          return  window.innerHeight -135
        },
        scrollerHeight: function() {
            return (window.innerHeight - 80) + 'px';
        }, 
    },
    methods: { 
        handleTime(active,index){
            this.currentIndex=index
            // ref 用在v-for上时，得到的是一个数组，用在普通元素上，直接是 dom名
            this.$nextTick(()=>{
                this.$refs[active['GPNM']][0].scrollIntoView({behavior:"smooth",block:"start"}) 
            })       
            //this.$refs[`#id${index}`].scrollIntoView({behavior:"smooth",block:"center"})    
        } ,
        loadMore(n) {
            return () => this.rangeNumber += 10 //每次滚动到底部可以新增条数 
        },
        tableMore(n) {
            return () => this.rangeNumber += 20 //每次滚动到底部可以新增条数 
        },
        fieldSet(){
            this.fieldWin =true
            this.fldCond =''
            var that =this
            getTabColOrData(that.v_many,that.v_username,that.v_password,'V_USER_TABFLD','frmid','107','idseq',this.v_idseq,'ishide','Y','' ).then((res) => {
                let obj_={}
                for (let k=0;k< res.data.result.length; k++){
                    obj_[res.data.result[k].FLDNO]='Y'
                    this.fldArr.push(res.data.result[k].FLDNO)
                }
                Object.assign(that.fldSel,JSON.parse(JSON.stringify(obj_)) )
                this.fldCond=JSON.stringify(this.fldArr).replaceAll("\"",'\'').replaceAll('[','').replaceAll(']','')
            })
        },
        //设置字段---确定
        fldOk(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/userTableFld?username='+this.v_username+'&password='+this.v_password,
                data: {p_json:this.fldCond, p_table:'USER_TABFLD',p_idseq:this.v_idseq, frmid:'107'},
            }).then(res=>{      
                if(res.data.result ==='OK')  {          
                    this.fieldWin=false
                    this.getTabCol()
                }else{
                    this.promptWin=true
                    this.prompt=res.data.result
                }        
            })
        },
        //设置字段
        fldChange(key,val){
            if (this.fldArr.indexOf(key)>-1){
                this.fldArr.splice(this.fldArr.indexOf(key),1)
            }
            if (val==='Y'){
                this.fldArr.push(key)
            }
            // console.log('sel:'+JSON.stringify(this.fldArr))
            if(this.fldArr.length>0){
                this.fldCond=JSON.stringify(this.fldArr).replaceAll("\"",'\'').replaceAll('[','').replaceAll(']','')
            } else{
                this.fldCond=''
            }    
        },
        //设置权限
        setPriv(){
            if (this.parempno){
                this.privDialog=true
                this.$nextTick(()=>{
                    this.$refs.refpriv.getFormData('userno',this.parempno,'','','','','') //初始查询用户表数据  
                })
            }else{
                this.promptWin =true
                this.prompt='请选择一条记录'
            }
              
        },
        getRowKey(row){
            return row.IDSEQ
        },
        //子件 --图片的取消和确定
        picExit(){
            this.picDialog =false
        },
        //确认按钮
        picSure(arr,filetype){
            this.picList=arr;
            this.saveAttData(arr,filetype);
            this.picDialog =false
        },
        //选择图片
        selPicture(val){
            this.picDialog =true
            this.selectAttType=val
            this.$nextTick(()=>{
                this.$refs.refdoc.fileArr=[]
                this.$refs.refdoc.addList=[]
                this.$refs.refdoc.filety=' in (\'jpg\',\'png\',\'gif\')'
            })           
            this.addtype ='添加图片'
        },
 
        attDel(idseq,listidesq){
            this.confirmWin=true
            this.cfmVal='attach'
            this.cfmprompt='你确定要删除吗?'
            this.cfmidseq=idseq
            this.cfmlstseq=listidesq
        },
        attClick(row, index) {
            this.imgsrc = row.FILEPATH
            this.attIndex=row.LSTSEQ
        },
        //保存选择的图片
        saveAttData(arr ){
            for(let i=0;i<arr.length;i++){ 
                let attItem={
                    IDSEQ:this.formCol.IDSEQ,
                    FILEPATH:arr[i],
                    FILETY:this.selectAttType,
                }
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/tableBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(attItem), p_table:'HR_ATTACH',p_idseq:''},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {          
                        this.getAttData(this.formCol.IDSEQ)
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.result
                    }        
                }) 
            }
            this.$nextTick(()=>{
                this.picList=[];
            });
        },
        //附件表
        getAttData(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_HR_attach','idseq',idseq).then((res) => {
                this.attData=[]
                if (res.data.length>0){
                    this.attData =res.data
                    this.imgsrc =res.data[0].FILEPATH
                    this.attIndex=res.data[0].LSTSEQ
                }
            })
        },
        //家庭成员表
        getHomeData(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'HR_RELATE','idseq',idseq).then((res) => {
                this.homeData=[]
                if (res.data){
                    this.homeData =res.data
                }
            })
        },
        homeClick(row, index) {
            this.homeRow = row
        },
        homeOk(){
            let vm =this
            if (!this.homeRow['EMPNM']){
                this.promptWin=true
                this.prompt='名称不能为空'
            }else if (!this.homeRow['WHAT']){
                this.promptWin=true
                this.prompt='与我的关系不能为空'
            }else{
                vm.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_json:JSON.stringify(Object.assign(this.homeRow,{IDSEQ:vm.formCol.IDSEQ})), p_table:'HR_RELATE',p_idseq:''},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {          
                        vm.homeModel =false   
                        vm.getHomeData(vm.formCol.IDSEQ );
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.result
                    }        
                })  
            }
        },
        homeAdd(){ // 新增事件
            if (this.formCol['IDSEQ']!==''){
                this.homeModel =true
                this.homeFlag='create'
                this.homeRow={IDSEQ:this.formCol['IDSEQ'],LSTSEQ:''}
            }else{
                this.promptWin=true
                this.prompt='员工ID为空时不能新增'
            }
        } ,
        homeEdit(){
            if (!this.homeRow['LSTSEQ']){
                this.promptWin=true
                this.prompt='请选择一条记录'
            }else{
                this.homeFlag ='edit'
                this.homeModel =true
            }
        },
        homeDel(){
            if (!this.homeRow['LSTSEQ']){
                this.promptWin=true
                this.prompt='请选择一条记录'
            }else{
                this.confirmWin=true
                this.cfmVal='home'
                this.cfmprompt='你确定要删除此行记录吗?'
            }
        },
        //工作经历表
        getWorkData(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'HR_WKLIST','idseq',idseq).then((res) => {
                this.workData=[]
                if (res.data){
                    this.workData =res.data
                }
            })
        },
        workClick(row, index) {
            this.workRow = row
        },
        workOk(){
            let vm =this
            if (!this.workRow['CORPNM']){
                this.promptWin=true
                this.prompt='公司名称不能为空'
            }else if (!this.workRow['DEPTNM']){
                this.promptWin=true
                this.prompt='部门名称不能为空'
            }else if (!this.workRow['FRDTTM']){
                this.promptWin=true
                this.prompt='入职日期不能为空'
            }else if (!this.workRow['TODTTM']){
                this.promptWin=true
                this.prompt='离职日期不能为空'
            }else{
                vm.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_json:JSON.stringify(Object.assign(this.workRow,{IDSEQ:vm.formCol.IDSEQ})), p_table:'hr_wklist',p_idseq:''},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {          
                        vm.workModel =false   
                        vm.getWorkData(vm.formCol.IDSEQ );
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.result
                    }        
                })  
            }
        },
        workAdd(){ // 新增事件
            if (this.formCol['IDSEQ']!==''){
                this.workModel =true
                this.workFlag='create'
                this.workRow={IDSEQ:this.formCol['IDSEQ'],LSTSEQ:''}
            }else{
                this.promptWin=true
                this.prompt='员工ID为空时不能新增'
            }
        } ,
        workEdit(){
            if (!this.workRow['LSTSEQ']){
                this.promptWin=true
                this.prompt='请选择一条记录'
            }else{
                this.workFlag ='edit'
                this.workModel =true
            }
        },
        workDel(){
            if (!this.workRow['LSTSEQ']){
                this.promptWin=true
                this.prompt ='请选择一条记录'
            }else{
                this.confirmWin=true
                this.cfmprompt='你确定要删除此行记录吗?'
                this.cfmVal='work'
            }
        },
        //教育背景表
        getEduData(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'HR_Edu','idseq',idseq).then((res) => {
                this.eduData=[]
                if (res.data){
                    this.eduData =res.data
                }
            })
        },
        eduAdd(){ //教育新增事件
            if (this.formCol['IDSEQ']!==''){
                this.eduModel =true
                this.eduFlag='create'
                this.eduRow={IDSEQ:this.formCol['IDSEQ'],LSTSEQ:''}
            }else{
                this.promptWin=true
                this.prompt='员工ID为空时不能新增'
            }
        } ,
        //表格行点击事件
        eduClick(row, index) {
            this.eduRow = row
        },
        eduEdit(){
            if (!this.eduRow['LSTSEQ']){
                this.promptWin=true
                this.prompt='请选择一条记录'
            }else{
                this.eduFlag ='edit'
                this.eduModel =true
            }
        },
        //删除确认
        cfmOk(val){
            var vm=this;
            if (val==='edu'){ //教育
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_key:'idseq',p_value:this.formCol.IDSEQ,p_key2:'lstseq',p_value2:this.eduRow['LSTSEQ'],p_table:'hr_edu'},
                }).then(res=>{      
                    if(res.data.code =='200')  {    
                        vm.getEduData(vm.formCol.IDSEQ );                          
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.message
                    }        
                }) 
            }else if (val==='home'){ //家庭成员
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_key:'idseq',p_value:this.formCol.IDSEQ,p_key2:'lstseq',p_value2:this.homeRow['LSTSEQ'],p_table:'hr_relate'},
                }).then(res=>{      
                    if(res.data.code =='200')  {    
                        vm.getHomeData(vm.formCol.IDSEQ );                          
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.message
                    }        
                }) 
            }else if (val==='cancel'){ //作废记录
                 this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_json:JSON.stringify({'IDSEQ':vm.rowIdseq,'ISCANCEL':'Y'}), p_table:'HR_EMP',frmid:''},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {          
                        vm.qryTabData()
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.message
                    }        
                })
            }else if(val==='work'){ //工作经历
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_key:'idseq',p_value:this.formCol.IDSEQ,p_key2:'lstseq',p_value2:this.workRow['LSTSEQ'],p_table:'hr_wklist'},
                }).then(res=>{      
                    if(res.data.code =='200')  {    
                        vm.getWorkData(vm.formCol.IDSEQ );                          
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.message
                    }        
                }) 
            }else if (val==='attach'){
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.basseUrl+'sysprivs/delTableRec?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_key:'idseq',p_value:this.cfmidseq,p_key2:'lstseq',p_value2:this.cfmlstseq,p_table:'hr_attach'},
                }).then(res=>{      
                    if(res.data.code =='200')  {    
                        vm.getAttData(vm.formCol.IDSEQ );                          
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.message
                    }        
                }) 
            }
            vm.confirmWin =false
        },
        eduDel(){
            if (!this.eduRow['LSTSEQ']){
                this.promptWin=true
                this.prompt='请选择一条记录'
            }else{
                this.confirmWin=true
                this.cfmprompt='你确定要删除此行记录吗？'
                this.cfmVal ='edu'
            }
        },
        eduOk(){
            //this.$refs['eduForm'].validate((valid)=>{
                let vm =this
                if (!this.eduRow['SCHNM']){
                    this.promptWin=true
                    this.prompt='学校名称不能为空'
                }else if (!this.eduRow['SCHLVL']){
                    this.promptWin=true
                    this.prompt='学历不能为空'
                }else if (!this.eduRow['FRDTTM']){
                    this.promptWin=true
                    this.prompt='起始日期不能为空'
                }else if (!this.eduRow['TODTTM']){
                    this.promptWin=true
                    this.prompt='结束日期不能为空'
                }else{
                    vm.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                        data: {p_json:JSON.stringify(Object.assign(this.eduRow,{IDSEQ:vm.formCol.IDSEQ})), p_table:'hr_edu',p_idseq:''},
                    }).then(res=>{      
                        if(res.data.result ==='OK')  {          
                            vm.eduModel =false   
                            vm.getEduData(vm.formCol.IDSEQ );
                        }else{
                            this.promptWin=true
                            this.prompt=res.data.result
                        }        
                    })  
                }
        },
        //发票明细表
        getInvData(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_HR_INV','idseq',idseq).then((res) => {
                this.invData=[]
                if (res.data){
                    this.invData =res.data
                }
            })
        },
        //收货地址表
        getShipData(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_HR_EMPSHIP','idseq',idseq).then((res) => {
            this.rcvData=[]
            if (res.data){
                    for (var k=0; k<res.data.length; k++){
                        let obj_={}
                        let row_=res.data[k]
                        let rcvplc_=''
                        if (row_.RCVPLC){
                            rcvplc_ =row_.RCVPLC.split(',')
                        }
                        Object.assign(obj_,res.data[k],{'RCVPLC':rcvplc_})
                        this.rcvData.push(obj_)
                    } 
            }
            })
        },
        nodeClick(val){
            this.v_lvl =val
            if(val<0){
                this.getFormData1('','','','','','','')
            }
            else{
                this.getFormData('','','','','','',' and deptno in (select cname from hr_frame start with code='+val+' connect by prior code=parentno)')
            }
            this.rangeNumber =40
        },
        //date picker change event
        dateChange(val){
            this.facdttm =val
        },
        dateChange1(val){
            this.birthdttm =val
        },
        //部门form
        btnExit(){
            this.treeModal =false
        },
        btnOk(val){ 
            this.treeModal =false
            this.formCol['DEPTNO'] =val
        },
        //选择部门名称
        srchDept(){
            this.treeModal =true
        },
        //产品form
        exitModal(){
            this.prdModal =false
        },
        okModal(val){
            this.formCol['PRDCAT'] =val
            this.prdModal =false
        },
        //选择产品类别
        searchPrd(){
            this.prdModal =true
        },
        // 选择国家 时赋值给 国家号码
        handleChange(value){
            this.formCol['CTRYCODE'] =filterArrObj('CODE',value[1],this.areaCol)['SUBNO2']
            this.formCol['AREACODE'] =filterArrObj('CODE',value[4],this.areaCol)['SUBNO2']
        }, 
        //表单确定按钮 两个特殊数据类型： null ,undefined 当为null时 用 ! ,为undefined 用 typeof
        modal_ok(){
            var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
            var emptype_=''
            var nation_='' //数组转字符串
            var englvl_=''
            var jobtitle_=''
            var vm =this
            if (!verify.test(this.formCol['MAILADDR'] ) &&this.formCol['MAILADDR']) {
                    this.promptWin=true
                    this.prompt= '邮箱格式错误, 请重新输入'          
            }else{
                if (typeof(this.formCol['EMPTYPE'])!=='undefined' && this.formCol['EMPTYPE']!==''){
                    emptype_ =this.formCol['EMPTYPE'].join(',') 
                } 
                if (typeof(this.formCol['ENGLVL'])!=='undefined' && this.formCol['ENGLVL']!==''){
                    englvl_ =this.formCol['ENGLVL'].join(',') 
                }
                if (typeof(this.formCol['JOBTITLE'])!=='undefined' && this.formCol['JOBTITLE']!==''){
                    jobtitle_ =this.formCol['JOBTITLE'].join(',') 
                } 
                if (typeof(this.formCol['NATION'])!=='undefined' ){
                    nation_ =this.formCol['NATION'].join(',') 
                }

                this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                    if (!this.formCol[item.FLDNO] ){
                        this.stfgBool =false 
                        this.promptWin=true
                        this.prompt=item.FLDNM+'不能为空'
                        return true
                    }else{
                        this.stfgBool =true  
                    }
                })
                //发起保存请求   
                if (this.stfgBool) {    
                    let obj_ ={}      
                    // Object.assign(obj_,vm.formCol,{'JOBTITLE':jobtitle_,'EMPTYPE': emptype_,'ENGLVL':englvl_,'NATION':nation_,'FACDTTM':this.facdttm,'BIRTHDTTM':this.birthdttm}) 
                    Object.assign(obj_,vm.formCol,{'JOBTITLE':jobtitle_,'EMPTYPE': emptype_,'ENGLVL':englvl_,'NATION':nation_}) 
                    this.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                        data: {p_json:JSON.stringify(obj_), p_table:'HR_EMP',frmid:'107'},
                    }).then(res=>{      
                        if(res.data.result ==='OK')  {          
                            vm.addModal =false   
                           // vm.qryTabData()
                            this.getFormData('','','','','','',' and deptno in (select cname from hr_frame start with code='+this.v_lvl+' connect by prior code=parentno)')
                        }else{
                            this.promptWin=true
                            this.prompt=res.data.result
                        }        
                    }) 
                }
            }
        },
 
        //查询其它标签内容
        queryEmpTab(idseq){
            this.getShipData(idseq)
            this.getInvData(idseq)
            this.getEduData(idseq)
            this.getWorkData(idseq)
            this.getHomeData(idseq)
            this.getAttData(idseq)
        },
        //新增记录
        addRec(){
            this.addModal =true
            this.type ='create'
            this.formCol ={'IDSEQ':''}
            this.rowIndex ='-1'
            this.queryEmpTab('-1')
            this.idType=''
            this.imgsrc=''
        },
        //修改记录
        editRec(){
            if (this.rowIndex==='-1'){
                this.promptWin=true
                this.prompt='请选择一条记录'
            }else{
                this.currentIndex='-1'
                this.addModal =true
                this.type ='edit'
                this.queryEmpTab(this.formCol.IDSEQ)
                if (this.formCol['SUPNO'] ){              
                    let index_ =this.supArr.some((subitem,index)=>{
                        if (subitem['IDNO']==this.formCol['SUPNO'] ){
                            this.supArr.unshift(this.supArr.splice(index , 1)[0]);
                        }
                    })       
                }
            }
        },
        //作废记录
        cancelRec(){
            if (this.rowIndex==='-1'){
                this.promptWin=true
                this.prompt='请选择一条记录'
            }else{
                this.confirmWin=true
                this.cfmprompt='你确定要作废此行记录吗？'
                this.cfmVal ='cancel'
            }
        },
        prevImg(){

        },
        //查询选择器事件
        elchange(value){
            let obj ={}
            obj =this.tabCol.find(item=>{
                return item.key===value
            })
            this.input_hold='请输入'+obj.title+' 的值'
        },
        //查询表格数据
        qryTabData(){
            this.getFormData('','','','','','','')
        },
 
        //表格行点击事件
        rowClick(row, index) {
            let nation_=row.NATION.toString()
            if(nation_!==''){
                Object.assign(row,{'NATION':nation_.split(',')})
            }else{
                Object.assign(row,{'NATION':[]})
            }
            this.formCol = JSON.parse(JSON.stringify(row))
            this.rowIndex =index
            this.rowIdseq =row.IDSEQ
            this.parempno =row.EMPNO
        },
        //获取表单数据
        getFormData(v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_){
            var vm=this ,v_cond=''
            this.loading =true
            this.tmpData=[]
            if (this.selparam){
                if (this.oper==='like'){
                    v_cond=v_cond+' and upper('+this.selparam+') like upper(\'%'+this.txtparam+'%\')'
                }else{
                    v_cond=v_cond+' and upper('+this.selparam+')'+this.oper+'upper(\''+this.txtparam+'\')'
                }
            }
            getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_HR_EMP',v_key,v_value,v_key2,v_value2,v_key3,v_value3,v_cond+cond_).then((res) => {              
                this.recNum =res.data.result.length
                for (var k=0; k<this.recNum; k++){
                    let obj_={}
                    let row_=res.data.result[k]
                    let nation_=''
                    let emptype_=''
                    let englvl_=''
                    let jobtitle_=''
                    if (row_.NATION){
                        nation_ =row_.NATION.split(',')
                    }
                    if (row_.EMPTYPE){
                        emptype_ =row_.EMPTYPE.split(',')
                    }
                    if (row_.ENGLVL){
                        englvl_ =row_.ENGLVL.split(',')
                    }
                    if (row_.JOBTITLE){
                        jobtitle_ =row_.JOBTITLE.split(',')
                    }
                    Object.assign(obj_,res.data.result[k],{'NATION':nation_,'EMPTYPE':emptype_,'ENGLVL':englvl_,'JOBTITLE':jobtitle_})
                    vm.tmpData.push(obj_)
                }   
                if (this.tmpData.length>0){
                    this.$refs['refhr'].setCurrentRow(this.tmpData[0])
                    this.rowClick(this.tmpData[0],0)
                }   
                vm.loading =false         
            });            
        },
        //获取表单数据
        getFormData1(v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_){
            var vm=this
            this.loading =true
            this.tmpData=[]
            getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_HR_EMP_RE',v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_).then((res) => {              
                for (var k=0; k<res.data.result.length; k++){
                    let obj_={}
                    let row_=res.data.result[k]
                    let nation_=''
                    let emptype_=''
                    let englvl_=''
                    let jobtitle_=''
                    if (row_.NATION){
                        nation_ =row_.NATION.split(',')
                    }
                    if (row_.EMPTYPE){
                        emptype_ =row_.EMPTYPE.split(',')
                    }
                    if (row_.ENGLVL){
                        englvl_ =row_.ENGLVL.split(',')
                    }
                    if (row_.JOBTITLE){
                        jobtitle_ =row_.JOBTITLE.split(',')
                    }
                    Object.assign(obj_,res.data.result[k],{'NATION':nation_,'EMPTYPE':emptype_,'ENGLVL':englvl_,'JOBTITLE':jobtitle_})
                    vm.tmpData.push(obj_)
                }      
                vm.loading =false         
            });            
        },
        //获取表单分组数据
        getFormGpData(){
            var vm=this
            getTabColOrData(this.v_formurl,this.v_username,this.v_password,'v_sys_form_fld','idseq','107','gpnm,gpsort','','fty','HEAD',' order by gpsort').then((res) => {
                vm.tabFrmCol = res.data.result               
            });               
        },
        //获取表列头字段  v-enter-number
        getTabCol(){
            var vm=this
            vm.tabCol=[]
            getTabColOrData(vm.v_many,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','107','fty','HEAD','','',' and fldno not in (select fldno from V_USER_TABFLD where idseq='+this.v_idseq+' and frmid=107 and ishide=\'Y\') order by sortby,lstseq' ).then((res) => {
                if (res.data.code==='200'){
                    for (let k=0; k<res.data.result.length; k++){
                        vm.tabCol.push({ //向数组的开头 添加序列号
                            title: res.data.result[k].title,
                            key: res.data.result[k].key,
                            align: res.data.result[k].align,
                            thide: res.data.result[k].THIDE,
                            fhide: res.data.result[k].FHIDE,
                            edcss: res.data.result[k].EDCSS,
                            width: res.data.result[k].width,
                            sortable: res.data.result[k].sortable?true:false,               
                        })
                    }
                }else{
                    let routeUrl = this.$router.resolve({
                        path: '/',
                        query: { },
                    });
                    window.open(routeUrl.href, '_self'); 
                }
            });
        },
        initField(){
            getTabColOrData(this.v_many,this.v_username,this.v_password,'v_sys_form_fld','idseq','107','fty','HEAD','','','   order by sortby,lstseq' ).then((res) => {
                this.fieldArr= res.data.result
            })
        }
    }
}
 
</script>
<style scoped lang="less" >
    .height{
        word-break: break-all;
        overflow-y: scroll;
    }
 
    .operation_show {
        display: none;
    }
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
 
    .title{
        font-weight:600;
        font-size:14px;
        color:#ccc
    }
    .label{
        font-weight:600;
        font-size:14px;
        margin-right:10px;
        color:rgb(53, 31, 31)
    }
    .siderCls{
        background: #FFF;
        margin: 3px auto;
    }    
 
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style>
<style lang="scss">
 
    .mt-9{
        margin-top: 9px;
    }
    .item-width  {
      
         color:#409EFF;
    }
    .el-dialog__body{
        padding: 1px 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:0px solid #ccc;
    } 
    .el-dialog__headerbtn { margin-top:5px;  }
    .el-dialog__headerbtn i { background:white;font-size: 20px; }
    
 
</style> 